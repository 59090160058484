import {useLocation} from 'react-router-dom'

export const Footer = () => {
  const location = useLocation()
  const isFindOrFound = location.pathname === '/ache' || location.pathname === '/achei'
  return !isFindOrFound ? (
    <footer>
      <div>© Achamos Sua Criança</div>
      <div className='bottom-right-content'>
        Entre em contato pelo{' '}
        <div
          onClick={() => {
            window.open('https://www.instagram.com/achamossuacrianca/')
          }}
          className='btn-insta'
        >
          Instagram
        </div>
      </div>
    </footer>
  ) : (
    <>&nbsp;</>
  )
}
