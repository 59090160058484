export const Policies: React.FC = () => {
  return (
    <div className='content policies'>
      <h1>Política de Privacidade e Proteção de Dados</h1>
      <section>
        <p>
          A plataforma “Achamos sua criança”, sabe que a proteção dos dados pessoais é um direito fundamental do Titular
          e manter a privacidade assegurada é um dever indispensável.
        </p>
        <p>
          Criamos essa Política de Privacidade e Proteção de Dados de forma clara e objetiva para que você (Titular)
          saiba exatamente quais dados são coletados, quais são as práticas adotadas pela plataforma “Achamos sua
          criança”, ao realizar o tratamento e o motivo pelo qual seus dados são solicitados ou coletados, demonstrando
          o nosso compromisso com a boa-fé, segurança, privacidade e transparência.
        </p>
        <p>
          A presente Política se aplica à todas as pessoas que acessarem nosso site e redes sociais e poderá ser
          aplicado a outras formas de coleta de dados que sejam necessários para prestação do serviço pela plataforma
          “Achamos sua criança”,
        </p>
      </section>
      <section>
        <h2>Agente de Tratamento</h2>
        <p>
          <strong>“Achamos sua criança”</strong>
        </p>
        <h2>Papel no Tratamento</h2>
        <p>Predominantemente Controladora</p>
        <h2>Natureza dos Dados:</h2>
        <ul>
          <li>1. Dados pessoais informados pelo Titular</li>
          <li>2. Dados coletados durante a prestação de serviço</li>
          <li>3. Dados fornecidos pela população</li>
          <li>4. Dados coletados de forma automática durante a utilização de nossos serviços</li>
        </ul>
        <h2>Finalidades do Tratamento de Dados</h2>
        <p>
          Utilizamos dados pessoais de crianças que sejam estritamente necessários para o cumprimento da prestação de
          nossos serviços, dentro e fora de nossas Plataformas Digitais, respeitando o direito dessas crianças.
        </p>
        <p>
          Considerando a situação de calamidade pública, decorrente da enchente no Estado do Rio Grande do Sul, em maio
          de 2024, e em conformidade com a Lei Geral de Proteção de Dados (LGPD), informamos que a coleta dos dados
          pessoais das crianças resgatadas e que se encontram sem os seus respectivos pais (pai, ou mãe), responsável
          (eis) ou tutor (es) serão submetidos aos seguintes tratamentos:
        </p>
      </section>
      <section>
        <h2>Bases Legais LGPD</h2>
        <ol>
          <li>1. Consentimento</li>
          <li>2. Proteção à vida</li>
          <li>3. Incolumidade física</li>
        </ol>

        <p>
          <strong>Art. 7º - Tratamento de Dados Pessoais:</strong>
        </p>
        <ul>
          <li>a. I – Consentimento dos pais, responsáveis, tutores e familiares que comprovarem vínculo parental;</li>
          <li>b. VII - Para a proteção da vida ou da incolumidade física do titular ou de terceiro.</li>
        </ul>

        <p>
          <strong>Art. 11 - Tratamento de Dados Pessoais Sensíveis:</strong>
        </p>
        <ul>
          <li>c)- Sem fornecimento de consentimento do titular, nas hipóteses em que for indispensável para</li>
          <li>d) Proteção da vida ou da incolumidade física do titular ou de terceiro.</li>
        </ul>

        <p>
          <strong>Art. 14 - Tratamento de Dados Pessoais de Crianças e Adolescentes:</strong>
        </p>
        <ul>
          <li>
            e) § 3º - Poderão ser coletados dados pessoais de crianças sem o consentimento quando a coleta for
            necessária para contatar os pais ou o responsável legal, utilizados uma única vez e sem armazenamento, ou
            para sua proteção, e em nenhum caso poderão ser repassados à terceiro, indicado pelo poder público, sem o
            consentimento.
          </li>
        </ul>

        <h2>Medidas de Proteção</h2>
        <p>
          Estamos comprometidos com a adoção de políticas e normas de boas práticas relativas à proteção de dados.
          Implementamos medidas de seguranças técnicas, físicas, administrativas e organizacionais pertinentes a
          segurança de seus dados.
        </p>
      </section>
      <section>
        <h2>Direitos do Titular</h2>
        <p>
          Na qualidade de Titular de dados pessoais, a Lei Geral de Proteção de Dados garante as crianças resgatadas,
          pais, responsáveis, tutores e familiares os seguintes direitos:
        </p>
        <ul>
          <li>
            1. Confirmação da existência de tratamento: Você poderá solicitar através de canal exclusivo para esta
            finalidade, a qualquer momento, que a plataforma “Achamos sua criança” lhe informe e confirme a existência
            de tratamento de seus dados em nossos arquivos eletrônicos ou físicos.
          </li>
          <li>
            2. Acesso aos dados: Você poderá solicitar, sempre que necessário, acesso aos dados pessoais existentes na
            base de dados da plataforma “Achamos sua criança”, desde que comprovado o vínculo parental com a criança.
          </li>
          <li>
            3. Correção de dados incompletos, inexatos ou desatualizado: Se você identificar que dados pessoais
            necessitam de correções ou estejam incompletos, inexatos ou desatualizados, você poderá encaminhar
            solicitação para o canal dedicado ao titular dos dados.
          </li>
          <li>
            4. Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade
            com a LGPD.
          </li>
          <li>5. Eliminação dos dados tratados com o consentimento do titular.</li>
          <li>
            6. Informação das entidades públicas e, eventualmente, abrigos privados com as quais o controlador realizou
            uso compartilhado de dados.
          </li>
        </ul>
      </section>
      <section>
        <h2>Utilização dos Dados</h2>
        <p>
          A plataforma “Achamos sua criança “coletará dados informados, ativamente, de crianças desaparecidas sejam
          esses fornecidos pela população em geral, entes públicos, abrigos privados, pais, responsáveis, tutores e
          familiares quando do cadastramento de seus dados pessoais no site.
        </p>
        <p>
          Poderão também ser coletados dados inseridos manualmente pelo Titular, em qualquer momento de interação com
          uma de nossas aplicações.
        </p>
        <p>
          Além disso, também poderemos armazenar informações coletadas automaticamente durante o uso de nossos serviços
          como por exemplo: IP, data e horários de interações, entre outras informações
        </p>
      </section>
      <section>
        <h2>Dados pessoais fornecidos pelo Titular:</h2>
        <p>
          O termo “dado pessoal” significa qualquer informação que seja relacionada a você, ou a criança, que permita
          identificação.
        </p>
        <p>
          Coletamos todos os dados pessoais informados pelo Titular através do cadastro em nossos canais de atendimento
          como por exemplo: nome, email, telefone e endereço completo.
        </p>
        <p>
          No caso de preenchimento do formulário de interesse em trabalhar conosco, além dos dados acima informados
          coletamos ainda: data de nascimento e foto que ficam de uso restrito para os órgãos públicos e protetivos do
          direito à criança, como o Conselho Tutelar e Polícia: Civil, Federal e Militar.
        </p>
      </section>
      <section>
        <h2>Dados pessoais coletados automaticamente: </h2>
        <p>
          {' '}
          Coletamos informações automaticamente, tais como características do dispositivo utilizado para acesso:
          navegador, sistema operacional, endereço de IP, cookies, além de informações sobre navegação dentro das
          aplicações como cliques, páginas acessadas, tempo de navegação entre outras informações coletadas de maneira
          automática.{' '}
        </p>
      </section>
      <section>
        <h2>Compartilhamento de dados:</h2>
        <p>
          {' '}
          Podemos, compartilhar os seus dados pessoais e de crianças com terceiros, tais como: Órgãos reguladores,
          autoridades ou entidades governamentais e abrigos privados. 4 Neste compartilhamento adotamos medidas
          apropriadas para garantir a segurança dos dados pessoais a fim de evitar alterações, perdas, vazamentos,
          tratamentos ou acessos não autorizados, através de boas práticas em segurança da informação, bloqueios físicos
          de acesso e todas as outras medidas técnicas que sejam necessárias.{' '}
        </p>
      </section>
      <section>
        <h2>Nãocompartilhamento de dados: </h2>
        <p>
          {' '}
          Não compartilhamos seus dados pessoais com quaisquer outros terceiros que não os mencionados, expressamente,
          nesta política.
        </p>
      </section>
      <section>
        <h2>COOKIES</h2>
        <p>
          Ao navegar em um site, informações pessoais e interesses do usuário são armazenados através de cookies, que
          são registros sobre sua visita no site. Mais informações sobre o uso de cookies estão disponíveis em nosso
          site e podem ser encontradas na “Política de Cookies”.
        </p>
      </section>
      <section>
        <h2>Armazenamento – conservação - eliminação dos dados</h2>
        <p>
          Qualquer dado pessoal coletado será tratado e armazenado de acordo com as melhores práticas de segurança. Para
          tanto a plataforma “Achamos sua criança” adota as precauções necessárias em observância às diretrizes sobre
          padrões de segurança estabelecidas nas legislações e regulamentações aplicáveis.
        </p>
        <p>
          As informações poderão ser conservadas para cumprimento de obrigação legal e enquanto permanecer o estado de
          calamidade, sempre respeitando os requisitos de tratamento de dados.
        </p>
        <p>
          Os dados pessoais serão eliminados quando expirar a sua finalidade e não forem mais necessários para cumprir
          qualquer obrigação legal ou quando o Titular solicitar o seu descarte, exceto se a manutenção do dado for
          expressamente autorizada por lei.
        </p>
      </section>
      <section>
        <h2>Canal de Privacidade</h2>
        <p>
          Se você precisar sanar dúvidas sobre como dados pessoais estão sendo tratados, ou queira formalizar alguma
          solicitação prevista pela Lei Geral de Proteção de Dados, deverá utilizar o nosso Canal de Privacidade através
          de nossa Encarregada de Dados( DPO) Alessandra Busato, CPF: 900.667.070-72, (51) 993249929, e-mail:
          alessandravbusato@gmail.com.
        </p>
      </section>
      <section>
        <h2>Considerações finais</h2>
        <p>
          A plataforma “Achamos sua criança” aplica todos os esforços para atender às solicitações no menor prazo
          possível, porém, com fatores justificáveis tais como o nivel de complexidade da ação requisitada, poderá
          atrasar ou impedir o atendimento de maneira rápida.
        </p>
        <p>
          Contudo, o Titular deve estar ciente que sua solicitação pode ser negada fundamentada em bases legais e demais
          necessidades inclusive para atendimento de políticas públicas.
        </p>
        <p>
          A política aqui exposta é regida, interpretada e executada em comum acordo as leis da República Federativa do
          Brasil, em especial a Lei Geral de Proteção de Dados Pessoais (LGPD), Lei nº 13.709/2018, independente das
          leis de outros países.
        </p>
        <p>
          Eventuais alterações nesta Política de Privacidade serão informadas por meio de postagem em nosso Site. Por
          isso, recomendamos que os Titulares acessem nossa Política periodicamente e observem a data de sua
          modificação, ao final deste documento, para confirmar eventual modificação.
        </p>
      </section>

      <p>A presente Política de Privacidade foi aprovada e publicada em 09/05/2024.</p>
    </div>
  )
}
