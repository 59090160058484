export const Lgpd: React.FC = () => {
  return (
    <div className='content lgpd'>
      <h1>Aviso Legal de Coleta de Dados de Crianças em Situação de Emergência</h1>

      <p>
        Considerando a situação de calamidade pública, decorrente da enchente no Estado do Rio Grande do Sul, em maio de
        2024, e em conformidade com a Lei Geral de Proteção de Dados (LGPD), informamos que a coleta dos dados pessoais
        das crianças resgatadas e que se encontram sem os seus respectivos pais (pai, ou mãe), responsável (eis) ou
        tutor (es) conforme descritos abaixo, é realizada com base nas seguintes hipóteses legais:
      </p>

      <ul>
        <li>
          Art. 7º - Tratamento de Dados Pessoais:
          <ul>
            <li>VII - Para a proteção da vida ou da incolumidade física do titular ou de terceiro.</li>
          </ul>
        </li>
        <li>
          Art. 11 - Tratamento de Dados Pessoais Sensíveis:
          <ul>
            <li>
              II - Sem fornecimento de consentimento do titular, nas hipóteses em que for indispensável para:
              <ul>
                <li>e) Proteção da vida ou da incolumidade física do titular ou de terceiro.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Art. 14 - Tratamento de Dados Pessoais de Crianças e Adolescentes:
          <ul>
            <li>
              § 3º - Poderão ser coletados dados pessoais de crianças sem o consentimento quando a coleta for necessária
              para contatar os pais ou o responsável legal, utilizados uma única vez e sem armazenamento, ou para sua
              proteção, e em nenhum caso poderão ser repassados a terceiro sem o consentimento.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        Dessa forma, a coleta dos dados pessoais das crianças encontradas em situação de emergência, incluindo nome
        completo, endereço, foto e telefone dos responsáveis, é realizada com o objetivo primordial de garantir a
        segurança e proteção desses menores, permitindo a localização e contato com seus pais ou responsáveis legais
        para assegurar seu bem-estar e incolumidade física, mental e emocional.
      </p>
      <p>
        Salientamos que os dados coletados serão utilizados exclusivamente para os fins mencionados acima, não sendo
        repassados a terceiros sem o consentimento prévio dos pais, ou responsáveis legais.
      </p>
      <p>
        Para mais informações sobre o tratamento de dados pessoais, favor contatar nossa Encarregada de Proteção de
        Dados (DPO) através dos meios disponibilizados abaixo:
      </p>

      <p>
        Alessandra Busato Instagram (
        <a href='https://www.instagram.com/alessandravbusato/' target='_blank'>
          @alessandravbusato
        </a>
        )
      </p>
    </div>
  )
}
