import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

export const Founded: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.body.style.backgroundColor = '#E63C2C'
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  const handleOnClick = () => {
    navigate('/lgpd')
  }

  return (
    <section className='found-container height-founded'>
      <div className='founded-area'>
        <h3 className='register-succefull'>Criança registrada com sucesso! Obrigado por ajudar</h3>
        <button className={`btn btn-found btn-found-form `} onClick={handleOnClick} type='button'>
          LGPD
        </button>
      </div>
    </section>
  )
}
