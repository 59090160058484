import {Route, BrowserRouter as Router, Routes} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'

import {Footer} from './components/footer'
import {Header} from './components/header'
// import {Find} from './pages/find'
import {Found} from './pages/found'
import {Founded} from './pages/founded'
import {Home} from './pages/home'
import {Lgpd} from './pages/lgpd'
// import {Who} from './pages/who'

import {Policies} from './pages/policies'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <Router>
      <div className={`container mx-auto site-container ${false && 'full-h'}`}>
        <Header />

        <Routes>
          <Route element={<Home />} path='/' />
          {/* <Route element={<Who />} path='/quem-somos' /> */}
          <Route element={<Lgpd />} path='/lgpd' />
          {/* <Route element={<Find />} path='/ache' /> */}
          <Route element={<Found />} path='/achei' />
          <Route element={<Founded />} path='/cadastrado' />
          <Route element={<Policies />} path='/politicas' />
        </Routes>

        <Footer />
      </div>
      <ToastContainer />
    </Router>
  )
}

export {App}
