import {ChangeEvent, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

import cidades from '../../api/cidades.json'
import camera from '../../assets/img/camera.svg'
import hand from '../../assets/img/hand-black.svg'

import '../../index.css'

export const Found: React.FC = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<DataItem>({
    abrigo: '',
    cidade: 0,
    endereco_abrigo: '',
    foto: '',
    idade: 0,
    nome: '',
    observacoes: '',
    responsavel: '',
    telefone_responsavel: '',
  })
  const [fileName, setFileName] = useState('')

  const isButtonDisabled = Object.keys(data)
    .map(key => {
      if (key !== 'observacoes' && key !== 'responsavel')
        // eslint-disable-next-line
        return data[key as keyof typeof data] === "";
    })
    .some(item => item)

  useEffect(() => {
    document.body.style.backgroundColor = '#E63C2C'
    return () => {
      document.body.style.backgroundColor = ''
    }
  }, [])

  const handleChangeData = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    if (!name) return
    setData(prev => ({...prev, [name]: value}))
  }

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      fetch(`${process.env.REACT_APP_API_URL}files`, {
        body: formData,
        method: 'POST',
      })
        .then(response => response.json())
        .then(data => {
          const {
            data: {id},
          } = data
          setData(prev => ({...prev, foto: id}))
          setFileName(file.name)
          toast.success('Imagem carregada com suessso')
        })
        .catch(error => {
          console.error('Error:', error)
          toast.error('Erro ao carregar a imagem')
        })
    }
  }

  const handleSendData = async () => {
    fetch(`${process.env.REACT_APP_API_URL}items/pessoa_externo`, {
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(response => response.json())
      .then(() => {
        toast.success('Criança cadastrada com sucesso')
        setData({
          abrigo: '',
          cidade: 0,
          endereco_abrigo: '',
          foto: '',
          idade: 0,
          nome: '',
          observacoes: '',
          responsavel: '',
          telefone_responsavel: '',
        })
        setFileName('')
        navigate('/cadastrado')
      })
      .catch(error => {
        console.error('Error:', error)
        toast.error('Erro ao cadastrar a criança')
      })
  }

  return (
    <section>
      <div className='found-container'>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleSendData()
          }}
          className='form-area'
        >
          <div className='form-grid three-columns'>
            <input
              className='input-select-items'
              name='nome'
              onChange={handleChangeData}
              placeholder='Nome*'
              type='text'
            />
            <input
              className='input-select-items'
              name='idade'
              onChange={handleChangeData}
              placeholder='Idade*'
              type='number'
            />
            <div className='form-select'>
              <select className='cursor-pointer input-select-items' name='cidade' onChange={handleChangeData}>
                {cidades.map(cidade => (
                  <option className='options' key={cidade.id} value={cidade.id}>
                    {cidade.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='form-grid two-columns'>
            <input
              className='input-select-items'
              name='abrigo'
              onChange={handleChangeData}
              placeholder='Abrigo*'
              type='text'
            />
            <input
              className='input-select-items'
              name='endereco_abrigo'
              onChange={handleChangeData}
              placeholder='Endereço do abrigo*'
              type='text'
            />
          </div>
          <div className='form-grid two-columns-first-largest'>
            <input
              className='input-select-items'
              name='telefone_responsavel'
              onChange={handleChangeData}
              placeholder='Telefone de quem está com a criança*'
              type='text'
            />
            <label className='cursor-pointer input-select-items' htmlFor='file'>
              {String(fileName || 'Foto*').slice(0, 10)}
              <img src={camera} />
            </label>
            <input className='input-file' id='file' onChange={handleUploadFile} placeholder='Foto*' type='file' />
          </div>
          <div className='form-grid two-columns'>
            <input
              className='input-select-items'
              name='responsavel'
              onChange={handleChangeData}
              placeholder='Mãe, Pai e/ou responsável'
              type='text'
            />
            <input
              className='input-select-items'
              name='observacoes'
              onChange={handleChangeData}
              placeholder='Observações'
              type='text'
            />
          </div>
          <div className='form-grid one-columns'>
            <button
              className={`btn btn-found btn-found-form ${isButtonDisabled && 'disabled'}`}
              disabled={isButtonDisabled}
              type='submit'
            >
              <img src={hand} /> Cadastrar uma criança!
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}

type DataItem = {
  abrigo: string
  cidade: number
  endereco_abrigo: string
  foto: string
  idade: number
  nome: string
  observacoes: string
  responsavel: string
  telefone_responsavel: string
}
