import {useNavigate} from 'react-router-dom'

import hand from '../../assets/img/hand.png'
// import magnif from '../../assets/img/MagnifyingGlass.png'

export const Home = () => {
  const navigate = useNavigate()
  return (
    <div className='content container-index'>
      {/* <div className='btn btn-find' onClick={() => navigate('/ache')}>
        <img src={magnif} />
        Ache uma criança
      </div> */}
      <div className='btn btn-found' onClick={() => navigate('/achei')}>
        <img src={hand} /> Achei
      </div>
    </div>
  )
}
