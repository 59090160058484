import {useLocation, useNavigate} from 'react-router-dom'

import Logo from '../../assets/img/logo-achamos.png'

export const Header: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const isRoot = location.pathname === '/'
  // const isFind = location.pathname === '/ache'
  const isFound = location.pathname === '/achei'

  return (
    <header>
      <h1 onClick={() => navigate('/')}>
        <img alt='Achamos sua criança' height={90} src={Logo} width={96} />
      </h1>
      <div className='header-right-content'>
        {/* <div className={`btn-menu ${isFound && 'btn-border-white'}`} onClick={() => navigate('/quem-somos')}>
          Quem somos
        </div> */}
        <div className={`btn-menu ${isFound && 'btn-border-white'}`} onClick={() => navigate('/lgpd')}>
          LGPD
        </div>
        <div className={`btn-menu ${isFound && 'btn-border-white'}`} onClick={() => navigate('/politicas')}>
          Políticas
        </div>
        {!isRoot && (
          <>
            {/* <div className={`btn-menu btn-find ${isFind && 'btn-bg-white'}`} onClick={() => navigate('/ache')}>
              Ache uma criança
            </div> */}
            <div className={`btn-menu btn-found ${isFound && 'btn-bg-white'}`} onClick={() => navigate('/achei')}>
              Achei !
            </div>
          </>
        )}
      </div>
    </header>
  )
}
